<template>
  <div class="home-wrapper">
    <div class="d-flex p-4">
      Is Viral?
      <label class="checker ml-3">
        <input
          v-model="isViral"
          id="isViral"
          v-on:change="toggleViral"
          class="checkbox"
          type="checkbox"
        />
        <div class="check-bg"></div>
        <div class="checkmark">
          <svg viewBox="0 0 100 100">
            <path d="M20,55 L40,75 L77,27"
              fill="none"
              stroke="#FFF"
              stroke-width="15"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </label>
    </div>
    <div class="home container" v-if="typeof images !== 'undefined'">
        <ImageCard
          v-for="image in images"
          v-bind:key="image.id"
          :src="getSrc(image)"
          :description="image.title"
          :details="image"
        />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageCard from '@/components/ImageCard.vue';
import * as Fetcher from '../fetcher/api';

export default {
  name: 'home',
  components: {
    ImageCard,
  },
  data() {
    return {
      isViral: true,
      ...this.mapState({
        images: 'images',
      }),
    };
  },
  mounted() {
    Fetcher.fetchImages();
  },
  methods: {
    getSrc(image) {
      if (image.is_album === true) {
        return `https://i.imgur.com/${image.cover}_d.jpg?maxwidth=520&amp;shape=thumb&amp;fidelity=high`;
      }
      if (image.link) {
        return image.link;
      }
      return '';
    },
    toggleViral() {
      const formData = new FormData(document.getElementById('sortForm'));
      const win = formData.get('window') !== null ? formData.get('window') : 'day';
      const section = formData.get('section') !== null ? formData.get('section') : 'hot';
      Fetcher.fetchImages(section, 'viral', win, this.isViral);
    },
  },
};
</script>

<style>

  .home {
    display: inline-block;
  }

  :root {
    --duration: 0.4s;
  }

  .checker {
    display: block;
    font-size: 20px;
    height: 20px;
    width: 50px;
    box-sizing: content-box;
    padding: 0.15em;
    border-radius: 0.25em;
    transition: transform var(--duration) ease;
    cursor: pointer;
  }

  .checkmark {
    width: 1em;
    height: 1em;
    transition: transform var(--duration) ease;
    transform: translateX(-0.4em);

    z-index: 5;
  }

  .checkmark svg {
    display: block;
    background: #e5e5e5;
    transform: translateX(0.4em);
    border-radius: 0.15em;
    transition: background-color var(--duration) ease, transform calc(var(--duration) * 1.5) ease;
  }

  .checkmark svg path {
    stroke-dasharray: 90 90;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset calc(var(--duration) / 3) linear calc(var(--duration) / 3);
  }


.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  visibility: hidden;
}

  .checkbox:checked ~ .checkmark {
    transform: translate(1.9em);
  }

  .checkbox:checked ~ .checkmark svg {
    background: #77c44c;
    transform: translate(-0.4em);
  }

  .checkbox:checked ~ .checkmark svg path {
    stroke-dashoffset: 0;
  }

  .check-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: white;
    z-index: 2;
  }
  .check-bg:before, .check-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }
  .check-bg:after {
    background: white;
  }
  .check-bg:before {
    transition: transform var(--duration) ease;
    transform: translateY(30%);
    transform-origin: bottom center;
    background: black;
    filter: blur(0.25em);
    opacity: 0.2;
    z-index: -1;
  }
  .checker.checker:active {
    transform: scale(0.85);
    transition-duration: calc(var(--duration) / 2);
  }
  .checker.checker:active .check-bg::before {
    transform: translateY(0) scale(0.8);
    opacity: 0.2;
  }
  *, *::before, *::after {
    box-sizing: border-box;
    position: relative;
  }
  html {
    height: 100%;
  }
  body {
    background-color: #f2f2f2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


</style>
